import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../containers/layout/layout';
import Header from '../../containers/layout/header';
import Footer from '../../containers/layout/footer';
import AuthorArea from '../../containers/home/author-area';
import { SectionTitle } from '../../components/shared/section-title';
import Blog from '../../components/blog/layout-two';
import Pagination from '../../components/pagination';
import styles from './BlogList.module.scss';

const BlogList: React.FC<any> = ({ data, pageContext, location }) => {
  const blogs = data.allMarkdownRemark.edges;
  const { currentPage, numberOfPages } = pageContext;
  console.log('pageContext', pageContext);
  return (
    <Layout>
      <SEO title={`Blog Page: ${currentPage}`} path={location.pathname} />
      <Header transparent />
      <div className="main-content">
        <AuthorArea />
        <main className={styles.MainWrapper}>
          <Container>
            <Row>
              <Col lg={12}>
                <section className={styles.RecentPostWrap}>
                  <SectionTitle mt="24px" mb="16px" title="Recent Articles" />
                  <div>
                    {blogs.map((blog: any) => (
                      <Blog
                        mb="50px"
                        key={blog.node.fields.slug}
                        content={{
                          ...blog.node.fields,
                          ...blog.node.frontmatter,
                          excerpt: blog.node.excerpt,
                        }}
                      />
                    ))}
                  </div>
                  <Pagination
                    rootPage="/"
                    currentPage={currentPage}
                    numberOfPages={numberOfPages}
                  />
                </section>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { fields: { category: { ne: "Site" } } }
    ) {
      edges {
        node {
          fields {
            slug
            dateSlug
            postID
          }
          frontmatter {
            category
            date(formatString: "LL")
            format
            tags
            title
            image {
              childImageSharp {
                fluid(
                  maxWidth: 510
                  maxHeight: 350
                  quality: 100
                  srcSetBreakpoints: 6
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          excerpt(pruneLength: 165, format: PLAIN, truncate: true)
        }
      }
    }
  }
`;

export default BlogList;
